import { provider } from './provider'

export async function getENS(address: string) {
  return provider.lookupAddress(address)
}

export async function getNonce(address: string) {
  return provider.getTransactionCount(address)
}

export async function getCode(address: string) {
  return provider.getCode(address)
}
