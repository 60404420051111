import { ParentSize } from '@visx/responsive'
import cx from 'classnames'
import dynamic from 'next/dynamic'
import { FC, memo } from 'react'
import useAbilityInfo from '~/hooks/useAbilityInfo'
import BaseCard from './BaseCard'

const Radar = dynamic(() => import('../Charts/Radar'), { ssr: false })

interface Props {}

const AbilityCard: FC<Props> = () => {
  const { data, isDone } = useAbilityInfo()
  return (
    <BaseCard
      title="Ability"
      className='w-full h-full'
      style={{ gridArea: 'ability' }}
    >
      <ParentSize>
        {(parent) =>
          data.length === 6 ? (
            <div className={cx(!isDone && 'animate-pulse')}>
              <Radar
                width={parent.width}
                height={parent.height}
                levels={1}
                showAxes={false}
                showAxesLabel
                data={data}
              />
            </div>
          ) : null
        }
      </ParentSize>
    </BaseCard>
  )
}

export default memo(AbilityCard)
