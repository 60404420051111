import { FC, HTMLAttributes, memo } from 'react'
import cx from 'classnames'
interface Props {
  title?: string
}

const BasicCard: FC<Props & HTMLAttributes<HTMLDivElement>> = props => {
  const { title, className, children, ...divProps } = props
  return (
    <div
      className={cx(
        'flex flex-col p-xs-card sm:p-card sm:pb-[15px] bg-white shadow-card rounded-xs-card sm:rounded-card overflow-hidden',
        className,
      )}
      {...divProps}
    >
      {title && (
        <h3 className="text-[#333333] text-card-xs-title sm:text-card-title font-bold">{title}</h3>
      )}
      {children}
    </div>
  )
}

export default memo(BasicCard)
