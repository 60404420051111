import { FC } from 'react'
import nftImage from '~/assets/nft.png'

interface Props {
  imageUrl?: string
  width?: number
  height?: number
  className?: string
  alt?: string
}

function isImage(url: string) {
  try {
    if (new URL(url).pathname.indexOf('.') === -1) {
      return true
    }
  } catch (e) {}

  return ['.png', '.jpg', '.jpeg', '.gif', '.webp', '.bmp', '.gif'].some((ext) => url.endsWith(ext))
}

export const NFTImage: FC<Props> = (props) => {
  const { imageUrl, className, alt, width, height } = props
  const src = !imageUrl || imageUrl === '?' ? nftImage.src : imageUrl
  if (isImage(src)) {
    return (
      // eslint-disable-next-line @next/next/no-img-element
      <img width={width} height={height} alt={alt} className={className} src={src} />
    )
  }

  return (
    <video
      muted
      autoPlay
      loop
      playsInline
      poster={src}
      className={className}
      width={width}
      height={height}
    >
      <source src={src} />
    </video>
  )
}
