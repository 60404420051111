import Image from 'next/image'
import { FC, memo } from 'react'
import WarnIcon from '~/assets/warn.png'
import useAddressParams from '~/hooks/useAddressParams'
import useContractCheck from '~/hooks/useContractCheck'

const ContractWarn: FC<{}> = () => {
  const address = useAddressParams()
  const { data: isContract } = useContractCheck(address)

  return isContract ? (
    <div className="m-auto background-gradient w-full max-w-[1210px] p-[2px] rounded-lg h-12">
      <div className="bg-white rounded-md w-full h-full">
        <div className="top-1/2 py-2 w-full text-center font-bold text-gradient">
          <div className="flex m-auto w-[450px]">
            <div className="flex-shrink-0">
              <Image src={WarnIcon} width={24} height={24} alt="icon" />
            </div>
            <div>This is a contract address, some data is not applicable.</div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default memo(ContractWarn)
