import Head from 'next/head'
import { useRouter } from 'next/router'
import { FC, memo } from 'react'
import AddressInput from '~/components/AddressInput'
import AbilityCard from '~/components/Cards/AbilityCard'
import AssociatedWalletsCard from '~/components/Cards/AssociatedWalletsCard'
import BaseInfoCard from '~/components/Cards/BaseInfoCard/Index'
import ContractWarn from '~/components/Cards/ContractWarn'
import FlipRecordCard from '~/components/Cards/FlipRecordCard'
import MainNFTHoldingCard from '~/components/Cards/MainNFTHoldingCard/Index'
import RecentActivitiesCard from '~/components/Cards/RecentActivitiesCard'
import TokenHoldingCard from '~/components/Cards/TokenHoldingCard'
import ExtDownload from '~/components/ExtDownload'
import Footer from '~/components/Footer'
import Navbar from '~/components/Navbar'
import useAddressParams from '~/hooks/useAddressParams'

interface Props {}

const Index: FC<Props> = () => {
  const router = useRouter()
  const address = useAddressParams()
  return (
    <>
      <Head>
        <title>Wallet Watch</title>
      </Head>
      <ExtDownload />
      <div className="w-screen mx-auto py-10 space-y-5 flex flex-col">
        <Navbar />
        <div className="w-full">
          <div className="xl:max-w-[1250px] mx-auto px-5 flex flex-row justify-between">
            <div className="w-[350px]">
              <AddressInput
                onAddressChange={(address) => {
                  router.push(`/${address.toLowerCase()}`)
                }}
              />
            </div>
            <div className="w-[300px]">{/* <AddressOperations /> */}</div>
          </div>
        </div>
        <ContractWarn />
        <div className="index-layout gap-3 sm:gap-5 mx-auto w-full sm:w-auto">
          <BaseInfoCard />
          <MainNFTHoldingCard />
          <AbilityCard />
          <TokenHoldingCard />
          <AssociatedWalletsCard />
        </div>
        <Footer />
      </div>
    </>
  )
}

export default memo(Index)
