import { useQuery } from 'react-query'
import { getAccount } from '~/api/opensea'
import useAddressParams from './useAddressParams'

export default function useOpenseaAccount() {
  const address = useAddressParams()
  return useQuery(
    ['opensea-account', address],
    () => {
      if (!address) {
        return null
      }
      return getAccount(address)
    },
    {
      staleTime: Infinity,
      enabled: !!address,
    },
  )
}
