import axios from 'axios'

const client = axios.create({
  baseURL: 'https://api.coingecko.com/api/v3',
})

//https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd
export async function getETHPrice() {
  const { data } = await client.get<{
    ethereum: {
      usd: number
    }
  }>('/simple/price', {
    params: {
      ids: 'ethereum',
      vs_currencies: 'usd',
    },
  })
  return data.ethereum.usd
}
