import { ethers } from 'ethers'
import { find, findIndex, min, sum } from 'lodash-es'
import { useEffect, useState } from 'react'
import { getNonce } from '~/api/ethers'
import { getFirstTransaction } from '~/api/etherscan'
import dayjs from '~/utils/day'
import useAddressParams from './useAddressParams'
import useFlipStats from './useFlipStats'
import useHoldingCollections from './useHoldingCollections'
import useTokenHolding from './useTokenHolding'

function getLogScore(value: number, maxValue: number, logFunc = Math.log) {
  if (value <= 1) {
    return 0
  }
  return min([1, logFunc(value) / logFunc(maxValue)])!
}
export default function useAbilityInfo() {
  const address = useAddressParams()
  const { data: holdingCollection } = useHoldingCollections()
  const { data: ethAmount } = useTokenHolding()
  const [dataParts, setDataParts] = useState<{ label: string; value: number }[]>([])
  const [data, setData] = useState<{ label: string; value: number }[]>([])
  const { data: flipStats } = useFlipStats('180d')

  useEffect(() => {
    if (!address || !holdingCollection || !ethAmount) {
      setDataParts([])
      return
    }
    Promise.all([getNonce(address), getFirstTransaction(address)]).then(([nonce, firstTx]) => {
      const firstTxTime = firstTx?.timeStamp || '0'
      const nftValue = sum(
        holdingCollection.map((col) => col.stats.floor_price * col.owned_asset_count),
      )
      const nftValueScore = getLogScore(nftValue, 200, Math.log2)!
      const nftAmount = sum(holdingCollection.map((col) => col.owned_asset_count))
      const nftAmountScore = getLogScore(nftAmount, 1000)
      const nonceScore = getLogScore(nonce, 1000)
      const activeDays = (dayjs().unix() - parseInt(firstTxTime)) / (3600 * 24)
      const activeDayScore = getLogScore(activeDays, 2000)

      const ethAmountScore = getLogScore(
        ethAmount.totalETHEquivalent.div(ethers.utils.parseEther('1')).toNumber(),
        100,
      )

      // console.log(
      //   `nft value ${nftValue}, nft count ${nftAmount}, nonce ${nonce}, flips profits ${flipsValueEth}, active days ${activeDays}, eth ${ethAmount}`,
      // )
      // console.log(
      //   `score: nft value ${nftValueScore}, nft count ${nftAmountScore}, nonce ${nonceScore}, flips profits ${flipsScore}, active days ${activeDayScore}, eth ${ethAmountScore}`,
      // )

      setDataParts([
        { label: 'NFT value', value: nftValueScore },
        { label: 'NFT amount', value: nftAmountScore },
        { label: 'Cash', value: ethAmountScore },
        { label: 'Nonce', value: nonceScore },
        // { label: 'Flip profits', value: flipsScore },
        { label: 'Active days', value: activeDayScore },
      ])
    })
  }, [address, holdingCollection, ethAmount])

  useEffect(() => {
    if (!flipStats) {
      return
    }
    const flipsValueKGwei = ethers.BigNumber.from(flipStats!.totalProfit).div(
      ethers.BigNumber.from('1000000000000'),
    )
    const flipsValueEth = flipsValueKGwei.toNumber() / 1_000_000

    const flipsScore = getLogScore(flipsValueEth, 100, Math.log2)!
    setData([...dataParts, { label: 'Flip profits', value: flipsScore }])
  }, [dataParts, flipStats])

  return { data, isDone: flipStats?.isDone }
}
