import { useCallback } from 'react'
import { QueryFunction, useQuery } from 'react-query'
import { getAddressTags as getAddressLabels } from '~/api/tag'

interface Label {
  value: string
  value_description?: string
}

export default function useAccountLabels(address?: string) {
  const fetcher = useCallback<QueryFunction<Label[] | undefined, [string, string]>>(
    ({ queryKey }) => {
      const address = queryKey[1]
      if (!address) {
        return
      }
      return getAddressLabels(address)
    },
    [],
  )

  return useQuery(['account-labels', address || ''], fetcher, {
    enabled: !!address,
    staleTime: Infinity,
  })
}
