import { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getFlipStats } from '~/api/trade'
import useAddressParams from './useAddressParams'

export default function useFlipStats(range: string) {
  const address = useAddressParams()
  const [firstFetchSuccess, setFirstFetchSuccess] = useState(false)

  const fetcher = useCallback(async () => {
    if (!address) {
      return
    }
    const stats = await getFlipStats(address, range)
    return stats
  }, [address, range])

  const { data, isFetched, refetch } = useQuery(['flip-stats', address, range], fetcher, {
    enabled: !!address,
    staleTime: 1 * 1000,
  })

  useEffect(() => {
    if (isFetched) {
      setFirstFetchSuccess(true)
    }
  }, [isFetched])
  
  useEffect(() => {
    if (!data) {
      return
    }
    const int = setInterval(() => {
      if (!data.isDone) {
        refetch()
      } else {
        clearInterval(int)
      }
    }, 1000)
    return () => {
      clearInterval(int)
    }
  }, [data, refetch])

  return { data, isFetching: !firstFetchSuccess }
}
