import { FC, memo } from 'react'
import useHoldingCollections from '~/hooks/useHoldingCollections'
import useTopHoldingNFTs from '~/hooks/useTopHoldingNFTs'
import BaseCard from '../BaseCard'
import HoldingList from './HoldingList'
import TopHolding from './TopHolding'

interface Props {}

const MainNFTHoldingCard: FC<Props> = () => {
  const { data: topHoldingNFTs, isFetching: fetchingTopHoldingNFTs } = useTopHoldingNFTs()
  const {
    data: holdingCollections,
    isFetching: fetchingpHoldingCollections,
  } = useHoldingCollections()

  return (
    <BaseCard
      title="Main NFT Holdings"
      className="w-full h-full"
      style={{ gridArea: 'nft-holding' }}
    >
      <div className="flex flex-col sm:flex-row lg:flex-col justify-center items-center mt-4 lg:mt-auto mx-[-15px] gap-6">
        <TopHolding topHolding={topHoldingNFTs} loading={fetchingTopHoldingNFTs} />
        <HoldingList holdingList={holdingCollections} loading={fetchingpHoldingCollections} />
      </div>
    </BaseCard>
  )
}

export default memo(MainNFTHoldingCard)
