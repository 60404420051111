import { Link } from '@nextui-org/react'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import * as Sentry from '@sentry/nextjs'
import Image from 'next/image'
import { FC, memo, useEffect } from 'react'
import { useAccount } from 'wagmi'
import Logo from '~/public/navbar-logo.png'

interface Props {}

const Navbar: FC<Props> = () => {
  const account = useAccount()
  useEffect(() => {
    if (account.address) {
      Sentry.setUser({ id: account.address })
    }
  }, [account])
  return (
    <nav className="w-full sm:mb-2 bg-white">
      <div className="flex items-center flex-col sm:flex-row xl:max-w-[1250px] mx-auto px-5 sm:h-[60px] justify-between">
        <div className="h-10">
          <Link href="/">
            <Image src={Logo} alt="logo" width={914 / 3} height={40} />
          </Link>
        </div>
        <div className="ml-auto">
          <div className="w-[150px] flex justify-end">
            <ConnectButton
              label="Connect"
              accountStatus="address"
              showBalance={false}
              chainStatus="none"
            />
          </div>
        </div>
      </div>
    </nav>
  )
}

export default memo(Navbar)
