/* eslint-disable @next/next/no-img-element */
import { Link, Tooltip } from '@nextui-org/react'
import { FC, memo, useMemo } from 'react'
import { Asset } from '~/api/opensea'
import { NFTImage } from '~/components/NFTimage'
import { getItemOpenSeaUrl } from '~/utils'

interface Props {
  topHolding?: Asset[]
  loading?: boolean
}

const TopHolding: FC<Props> = (props) => {
  const { topHolding, loading = false } = props

  if (loading || !topHolding) {
    return <div className="w-[296px] h-[296px] bg-placeholder animate-pulse rounded"></div>
  }

  return (
    <div className="w-[296px] h-[296px] relative">
      <Group topHolding={topHolding} />
    </div>
  )
}

const Group = memo<{ topHolding: Asset[] }>((props) => {
  const { topHolding } = props

  const list = useMemo(() => topHolding.filter((item) => item.image_url), [topHolding])

  switch (list.length) {
    case 5:
      return (
        <>
          {[
            <NFTImage
              key={0}
              imageUrl={list[0].image_url}
              className="w-[143px] h-[143px] absolute top-0 left-0 rounded object-cover"
              alt={list[0].collection.name}
            />,
            <NFTImage
              key={1}
              imageUrl={list[1].image_url}
              className="w-[120px] h-[120px] absolute bottom-0 left-[33px] rounded object-cover"
              alt={list[1].collection.name}
            />,
            <NFTImage
              key={2}
              imageUrl={list[2].image_url}
              className="w-[100px] h-[100px] absolute top-16 left-[133px] rounded object-cover"
              alt={list[2].collection.name}
            />,
            <NFTImage
              key={3}
              imageUrl={list[3].image_url}
              className="w-[96px] h-[96px] absolute bottom-[53px] right-0 rounded object-cover"
              alt={list[3].collection.name}
            />,
            <NFTImage
              key={4}
              imageUrl={list[4].image_url}
              className="w-[67px] h-[67px] absolute top-6 right-0 rounded object-cover"
              alt={list[4].collection.name}
            />,
          ].map((img, i) => (
            <Link
              key={i}
              href={getItemOpenSeaUrl(list[i].asset_contract.address, list[i].token_id.toString())}
              target="_blank"
            >
              {img}
            </Link>
          ))}
        </>
      )
    case 4:
      return (
        <>
          {[
            <NFTImage
              key={0}
              imageUrl={list[0].image_url}
              className="w-[143px] h-[143px] absolute top-0 left-0 rounded object-cover"
              alt="NFT"
            />,
            <NFTImage
              key={1}
              imageUrl={list[1].image_url}
              className="w-[120px] h-[120px] absolute bottom-0 right-[68px] rounded object-cover"
              alt="NFT"
            />,
            <NFTImage
              key={2}
              imageUrl={list[2].image_url}
              className="w-[100px] h-[100px] absolute top-[57px] left-[129px] rounded object-cover"
              alt="NFT"
            />,
            <NFTImage
              key={3}
              imageUrl={list[3].image_url}
              className="w-[77px] h-[77px] absolute bottom-[72px] right-0 rounded object-cover"
              alt="NFT"
            />,
          ].map((img, i) => (
            <Link
              key={i}
              href={getItemOpenSeaUrl(list[i].asset_contract.address, list[i].token_id.toString())}
              target="_blank"
            >
              {img}
            </Link>
          ))}
        </>
      )
    case 3:
      return (
        <>
          {[
            <NFTImage
              key={0}
              imageUrl={list[0].image_url}
              className="w-[143px] h-[143px] absolute top-0 left-0 rounded object-cover"
              alt="NFT"
            />,
            <NFTImage
              key={1}
              imageUrl={list[2].image_url}
              className="w-[131px] h-[131px] absolute top-[57px] right-0 rounded object-cover"
              alt="NFT"
            />,
            <NFTImage
              key={2}
              imageUrl={list[1].image_url}
              className="w-[119] h-[119px] absolute bottom-0 left-[77px] rounded object-cover"
              alt="NFT"
            />,
          ].map((img, i) => (
            <Link
              key={i}
              href={getItemOpenSeaUrl(list[i].asset_contract.address, list[i].token_id.toString())}
              target="_blank"
            >
              {img}
            </Link>
          ))}
        </>
      )
    case 2:
      return (
        <>
          {[
            <NFTImage
              key={0}
              imageUrl={list[0].image_url}
              className="w-[164px] h-[164px] absolute top-0 left-0 rounded object-cover"
              alt="NFT"
            />,
            <NFTImage
              key={1}
              imageUrl={list[1].image_url}
              className="w-[164px] h-[164px] absolute top-[110px] left-[110px] rounded object-cover"
              alt="NFT"
            />,
          ].map((img, i) => (
            <Link
              key={i}
              href={getItemOpenSeaUrl(list[i].asset_contract.address, list[i].token_id.toString())}
              target="_blank"
            >
              {img}
            </Link>
          ))}
        </>
      )
    case 1:
      return (
        <>
          <Link
            href={getItemOpenSeaUrl(list[0].asset_contract.address, list[0].token_id.toString())}
            target="_blank"
          >
            <NFTImage
              imageUrl={list[0].image_url}
              className="w-[296px] h-[296px] absolute top-0 left-0 rounded object-cover"
              alt="NFT"
            />
          </Link>
        </>
      )
    default:
      return null
  }
})

Group.displayName = 'NFT_Group'

export default memo(TopHolding)
