import { sortBy, take } from 'lodash-es'
import { useCallback } from 'react'
import { useQuery } from 'react-query'
import { getHoldingCollections } from '~/api/opensea'
import useAddressParams from './useAddressParams'

export default function useHoldingCollections() {
  const address = useAddressParams()

  const fetcher = useCallback(async () => {
    if (!address) return

    const cols = await getHoldingCollections(address)
    return take(
      sortBy(cols, (col) => {
        return -col.owned_asset_count * col.stats.seven_day_average_price
      }),
      5,
    )
  }, [address])

  return useQuery(['holding-collections', address], fetcher, {
    enabled: !!address,
    staleTime: 10000,
  })
}
