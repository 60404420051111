import axios from 'axios'
import { groupBy, partition, reduce, sortBy, take } from 'lodash-es'

interface AddressProfile {
  prop: string
  value: string
  description: string
}

const excludedProps = ['ENS Name', 'ENS Name (Reverse)']

const labelPrioritys: { [key: string]: { [key: string]: number } } = {
  'NFT Collector': {
    'Legendary NFT Trader': 1,
    'Epic NFT Trader': 2,
    'Rare NFT Trader': 3,
    'NFT Blue Chip Holder': 4,
    'Uncommon NFT Trader': 5,
    'Diversified NFT Holder': 6,
    'OpenSea Trader': 7,
  },
}

export async function getAddressTags(address: string) {
  const { data } = await axios.get<AddressProfile[]>(
    `https://datn-api.datawaves.xyz/api/v1/profiles/${address}`,
  )

  let labels = data.filter((p) => !excludedProps.includes(p.prop))
  labels = reduce(
    groupBy(labels, 'prop'),
    (p, values, prop) => {
      if (prop in labelPrioritys) {
        p.push(
          ...take(
            sortBy(values, (v) => labelPrioritys[prop][v.value] || Infinity),
            1,
          ),
        )
      } else {
        p.push(...values)
      }
      return p
    },
    [] as typeof labels,
  )

  return labels
}
