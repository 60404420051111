import axios from 'axios'

const API_KEY = '80c01f4fb2f8411f8c7d5ba66827f6bd'

const client = axios.create({
  baseURL: 'https://api.opensea.io',
  headers: {
    'X-Api-Key': API_KEY,
  },
})

const proxyClient = axios.create({
  baseURL: '/api/opensea',
})

export { client, proxyClient }
