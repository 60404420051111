import { useCallback } from 'react'
import useAddressParams from './useAddressParams'
import useHoldingCollections from './useHoldingCollections'
import { flow, compact, groupBy, sortBy, mapValues, sample, values } from 'lodash/fp'
import { Asset, getOwnedAssets } from '~/api/opensea'
import { useQuery } from 'react-query'

export default function useTopHoldingNFTs() {
  const { data: holdingCollections } = useHoldingCollections()
  const address = useAddressParams()

  const fetcher = useCallback(async () => {
    if (!address || !holdingCollections?.length) {
      return
    }

    const assets = await getOwnedAssets(
      address,
      holdingCollections.map((it) => it.primary_asset_contracts[0].address),
    )
    const collectionSeq = holdingCollections
      .map((col) => col.primary_asset_contracts[0].address)
      .reduce((map, c, i) => {
        map[c] = i
        return map
      }, {} as { [key: string]: number })
    return flow(
      groupBy<Asset>((it) => it.asset_contract.address),
      mapValues((v) => sample(v)),
      values,
      compact,
      sortBy<Asset>((it) => collectionSeq[it.asset_contract.address]),
    )(assets)
  }, [address, holdingCollections])

  return useQuery(['top-holding-NFT', address], fetcher, {
    enabled: !!(address && holdingCollections?.length),
    staleTime: 10000,
  })
}
