import { useCallback } from 'react'
import { getENS } from '~/api/ethers'

import { useQuery } from 'react-query'
import { useRouter } from 'next/router'

export default function useENS(address?: string | null) {
  const fetcher = useCallback(async () => {
    if (!address) return
    const ens = await getENS(address)
    return ens
  }, [address])

  return useQuery(['ens', address], fetcher, { staleTime: Infinity })
}
