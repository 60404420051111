import { FC, memo } from 'react'
import { formatAddress } from '~/utils/formatter'
import Image from 'next/image'
import TransiactionItem from './TransiactionItem'
import useAssociatedTxn from '~/hooks/useAssociatedTxn'
import Link from 'next/link'

interface Props {
  selectedAddressENS?: string | null
  selectedAddress: string
  currentIndex: number
}

const AssociatedDetail: FC<Props> = props => {
  const { selectedAddressENS, selectedAddress, currentIndex } = props
  const { data: txns, isFetching } = useAssociatedTxn(selectedAddress)

  return (
    <>
      <div className="flex items-center mb-3 sm:mb-5">
        <h3 className="text-[#333333] text-card-xs-title sm:text-card-title font-bold">
          Details with <span className="text-gradient">#{currentIndex + 1}</span>
        </h3>
        <div className="h-[32px]">
          <div className="mt-1">
            <Link href={`/${selectedAddress}`} passHref>
              <a
                className="flex items-center bg-[#E3F4FF] rounded-full px-2 py-0.5 ml-2.5"
                target="_blank"
                rel="noreferrer"
              >
                <span className="text-[#0E98FF] text-inter-xs">
                  {selectedAddressENS ? selectedAddressENS : formatAddress(selectedAddress)}
                </span>
                <div className="w-3 h-3 ml-1 flex">
                  <Image src="/link.svg" width={12} height={12} alt="link" />
                </div>
              </a>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-4 overflow-y-auto h-full">
        {isFetching ? (
          <>
            <div className="h-9 rounded bg-placeholder animate-pulse" />
            <div className="h-9 rounded bg-placeholder animate-pulse" />
            <div className="h-9 rounded bg-placeholder animate-pulse" />
          </>
        ) : (
          txns?.map(txn => <TransiactionItem key={txn.id} {...txn} />)
        )}
      </div>
    </>
  )
}

export default memo(AssociatedDetail)
