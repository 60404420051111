import Image, { StaticImageData } from 'next/image'
import { FC, memo, useMemo } from 'react'
import TwitterImage from '~/public/twitter.png'
import OpenseaImage from '~/public/opensea.png'
import EtherScanImage from '~/public/etherscan.png'
import useAddressParams from '~/hooks/useAddressParams'
import cx from 'classnames'

interface FooterProps {
  twitter?: string
  opensea?: string
  loading?: boolean
}

const Footer: FC<FooterProps> = props => {
  const { twitter, opensea, loading = false } = props
  const address = useAddressParams()

  const etherscanLink = useMemo(() => {
    if (!address) return
    return `https://etherscan.io/address/${address}`
  }, [address])

  return (
    <div className="flex items-center p-card py-[15px] h-[74px]">
      <span className="font-bold text-inter-sm">Links</span>
      {loading || !opensea ? (
        <div className="w-24 h-8 sm:h-11 bg-placeholder rounded animate-pulse ml-auto" />
      ) : (
        <div className="flex items-center space-x-5 ml-auto">
          <LinkButton href={twitter} src={TwitterImage} alt="twitter link" />
          <LinkButton href={opensea} src={OpenseaImage} alt="opensea link" />
          <LinkButton href={etherscanLink} src={EtherScanImage} alt="etherscan link" />
        </div>
      )}
    </div>
  )
}
Footer.displayName = 'BaseInfoCardFooter'

const LinkButton = memo((props: { href?: string; alt: string; src: string | StaticImageData }) => {
  const { href, alt, src } = props
  if (href === undefined) {
    return (
      <div className="w-8 h-8 sm:w-11 sm:h-11 bg-placeholder rounded-full animate-pulse ml-auto" />
    )
  }

  const anchorProps = href
    ? {
        href,
        target: '_blank',
        rel: 'noreferrer',
      }
    : {
        href: '#',
      }

  return (
    <a
      className={cx('w-8 h-8 sm:w-11 sm:h-11 ', !href && 'opacity-20 cursor-not-allowed')}
      {...anchorProps}
    >
      <Image src={src} alt={alt} width={44} height={44} />
    </a>
  )
})
LinkButton.displayName = 'LinkButton'

export default memo(Footer)
