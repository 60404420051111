import { FC, memo } from 'react'

interface Props {}

const BlurBackground: FC<Props> = () => {
  return (
    <div className="absolute left-[110px] -top-7 z-0 w-[438px] h-[438px] blur-card-blur-bg">
      <div className="absolute w-72 h-72 bg-[#FF9110] top-8 left-0 rounded-full " />
      <div className="absolute w-72 h-72 bg-[#4A72FF] bottom-0 left-3 rounded-full" />
      <div className="absolute w-72 h-72 bg-[#0EAAFF] top-0 right-0 rounded-full" />
    </div>
  )
}

export default memo(BlurBackground)
