import { FC, memo } from 'react'
import dayjs from '~/utils/day'

interface Props {
  attributes?: {
    firstTxTime: string
    lastTxTime: string
    majorActiveTime: string
  }
  loading?: boolean
}

const PLACEHOLDER_COUNT = 3
const PLACEHOLDER_ARRAY = Array(PLACEHOLDER_COUNT).fill(0)

const Attributes: FC<Props> = props => {
  const { attributes, loading = false } = props
  return (
    <div className="flex flex-row z-10 pt-xs-card sm:pt-card gap-2 text-inter-xs sm:text-inter-sm ">
      {loading || !attributes ? (
        PLACEHOLDER_ARRAY.map((_, index) => (
          <AttributeNodePlaceholder key={`placeholder-${index}`} />
        ))
      ) : (
        <>
          <div className="flex flex-col min-w-[25%] w-fit h-9">
            <span className="text-inter-xs text-white/80 truncate">Active since</span>
            <span className="w-full text-white font-bold mt-[3px] truncate">
              {dayjs.unix(parseInt(attributes.firstTxTime)).format('YYYY/MM/DD')}
            </span>
          </div>
          <div className="flex flex-col min-w-[25%] w-fit h-9">
            <span className="text-inter-xs text-white/80 truncate">Last active time</span>
            <span className="w-full text-white font-bold mt-[3px] truncate">
              {dayjs.unix(parseInt(attributes.lastTxTime)).fromNow(false)}
            </span>
          </div>
          <div className="flex flex-col min-w-[35%] w-fit h-9">
            <span className="text-inter-xs text-white/80">Major active period</span>
            {props.attributes?.majorActiveTime ? (
              <div className="w-full flex flex-row">
                <span className="text-white font-bold mt-[3px]">
                  {props.attributes.majorActiveTime}
                </span>
                <span className="text-white mt-[3px]">(your time)</span>
              </div>
            ) : (
              "Don't Know"
            )}
          </div>
        </>
      )}
    </div>
  )
}

const AttributeNodePlaceholder = memo(() => {
  return <div className="flex w-[30%] h-9 bg-placeholder animate-pulse rounded"></div>
})
AttributeNodePlaceholder.displayName = 'AttributeNodePlaceholder'

export default memo(Attributes)
