import { compact, findIndex, keyBy, uniqBy } from 'lodash-es'
import { useCallback } from 'react'
import { useQuery } from 'react-query'
import { batchGetTokens } from '~/api/reservoir'
import { getNFTTransfers } from '~/api/trade'
import useAddressParams from './useAddressParams'
import useAssociatedWallets from './useAssociatedWallets'

export default function useAssociatedTxn(address: string) {
  const original = useAddressParams()
  const { data: addressList } = useAssociatedWallets()
  const fetcher = useCallback(async () => {
    if (!address || !original || !addressList) {
      return []
    }
    const trades = await getNFTTransfers(original, address)

    const assets = await batchGetTokens(
      uniqBy(
        trades.map((trade) => ({ contractAddress: trade.nft_contract, tokenId: trade.token_id })),
        (asset) => `${asset.contractAddress}/${asset.tokenId}`,
      ),
    )
    const assetsMap = keyBy(assets, (asset) => `${asset.contract.toLowerCase()}/${asset.tokenId}`)
    return compact(
      trades.map((trade, i) => {
        const type: 'send' | 'receive' =
          trade.buyer.toLowerCase() == original.toLowerCase() ? 'receive' : 'send'
        const asset = assetsMap[`${trade.nft_contract.toLowerCase()}/${trade.token_id}`]
        if (!asset) {
          return null
        }
        return {
          id: String(i + 1),
          type,
          ...{
            from:
              type === 'receive'
                ? `#${findIndex(addressList.transfers, (it) => it.address == trade.seller) + 1}`
                : undefined,
            to:
              type === 'receive'
                ? undefined
                : `#${findIndex(addressList.transfers, (it) => it.address == trade.buyer) + 1}`,
          },
          token: {
            collection: asset.collection.name,
            tokenName: asset.name,
            tokenId: trade.token_id,
            contract: trade.nft_contract,
          },
          timestamp: trade.time,
        }
      }),
    )
  }, [address, addressList, original])
  return useQuery(['associated-detail', original, address], fetcher, {
    enabled: !!address,
    staleTime: 10000,
  })
}
