import { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getENS } from '~/api/ethers'
import { getNFTTransferStats } from '~/api/trade'
import useAddressParams from './useAddressParams'

export interface AssociatedWallet {
  address: string
  ens?: string | null
  count: number
}

export default function useAssociatedWallets() {
  const address = useAddressParams()
  const [firstFetchSuccess, setFirstFetchSuccess] = useState(false)

  const fetcher = useCallback(async () => {
    if (!address) return
    const { data, isDone } = await getNFTTransferStats(address)
    const transfers = await Promise.all(
      data.map((trans) => {
        return getENS(trans.address).then((ens) => {
          return {
            ens,
            ...trans,
          }
        })
      }),
    )
    return { transfers, isDone }
  }, [address])
  const { data, refetch, isFetched } = useQuery(['associatedWallets', address], fetcher, {
    enabled: !!address,
    staleTime: 10000,
  })

  useEffect(() => {
    if (isFetched) {
      setFirstFetchSuccess(true)
    }
  }, [isFetched])

  useEffect(() => {
    if (!data) {
      return
    }
    const int = setInterval(() => {
      if (!data?.isDone) {
        refetch()
      } else {
        clearInterval(int)
      }
    }, 1000)
    return () => {
      clearInterval(int)
    }
  }, [data, refetch])

  return { data, isFetching: !firstFetchSuccess }
}
