import axios from 'axios'

export async function getTwitter(address: string) {
  const { data } = await axios.post<{
    data: {
      identity: {
        address: string
        twitter: { handle: string }
      }
    }
  }>('https://api.cybertino.io/connect/', {
    operationName: 'GetIdentity',
    variables: { address: address },
    query: `query GetIdentity($address: String!) {
        identity(address: $address) {
          address
          domain
          twitter {
            handle
            verified
          }
          
        }
      }`,
  })
  return data?.data?.identity.twitter.handle 
}
