import { useQuery } from 'react-query'
import { getCode } from '~/api/ethers'

export default function (address?: string) {
  const query = useQuery(
    ['getcode', address],
    async () => {
      if (!address) {
        return false
      }
      const code = await getCode(address)      
      console.log('code',code)
      return code !== '0x'
    },
    {
      enabled: !!address,
      staleTime: Infinity,
    },
  )
  return query
}
