import useAddressParams from './useAddressParams'
import useOpenseaAccount from './useOpenSeaAccount'
import useSocialLinks from '~/hooks/useSocialLinks'
import useAccountLabels from '~/hooks/useAccountLabels'
import useAccountAttributes from '~/hooks/useAccountAttributes'

type Profile = {
  imgUrl?: string
  address?: string
  screenName?: string
  twitter?: string
  opensea?: string
  attributes?: {
    firstTxTime: string
    lastTxTime: string
    majorActiveTime: string
  }
  labels?: { value: string; value_description?: string }[]
}

export default function useAccountInfo(): Profile {
  const address = useAddressParams()
  const { data: openseaAccount } = useOpenseaAccount()
  const { data: socialLinks } = useSocialLinks()
  const { data: accountLabels } = useAccountLabels(address)
  const { data: attributes } = useAccountAttributes(address)

  return {
    address,
    screenName: openseaAccount?.name,
    imgUrl: openseaAccount?.profile_img_url,
    twitter: socialLinks?.twitter,
    opensea: `https://opensea.io/${address}`,
    attributes,
    labels: accountLabels,
  }
}
