import { Text, Tooltip } from '@nextui-org/react'
import { FC } from 'react'

const Labels: FC<{ labels?: { value: string; value_description?: string }[] }> = ({ labels }) => {
  return (
    <div className="flex flex-row gap-x-2 mt-4 flex-wrap z-20">
      {(labels || []).map((label, i) => {
        return (
          <span key={i} className="bg-white/20 px-2 py-0 my-1 rounded-xl whitespace-nowrap">
            <Tooltip content={label.value_description} color="invert">
              <Text color="white" size={12} margin="0" weight={'bold'}>
                {label.value}
              </Text>
            </Tooltip>
          </span>
        )
      })}
    </div>
  )
}

export default Labels
