import { Link } from '@nextui-org/react'
import cx from 'classnames'
import { ethers, utils } from 'ethers'
import Image from 'next/image'
import { FC, memo } from 'react'
import dollarIcon from '~/assets/dollar.png'
import ETHIcon from '~/assets/eth.png'
import useAddressParams from '~/hooks/useAddressParams'
import useTokenHolding from '~/hooks/useTokenHolding'
import { formatPrice } from '~/utils'
import BaseCard from './BaseCard'

interface Props {}

const LINE_HEIGHT = 30
const TokenHoldingCard: FC<Props> = () => {
  const { data: balance, isFetching } = useTokenHolding()
  const address = useAddressParams()
  return (
    <BaseCard title="Cash" className="w-full h-full">
      {balance ? (
        <div className="m-auto text-center">
          <div className="text-[#C7C7C7] text-sm">Total</div>
          <div className="text-gradient font-bold text-token-xs-holding sm:text-token-holding m-auto">
            {formatPrice(balance.totalETHEquivalent)}
          </div>
        </div>
      ) : (
        <div className="h-12 bg-placeholder animate-pulse w-40 m-auto rounded" />
      )}
      <hr />
      <div className="h-20 mt-4 mb-2 space-y-2">
        <div className={cx('flex flex-row w-full items-center', `h-[${LINE_HEIGHT}px]`)}>
          <div className="h-full">
            <Image src={ETHIcon} width={LINE_HEIGHT} height={LINE_HEIGHT} alt="token icon" />
          </div>
          <span className="ml-2 text-xs">ETH&WETH</span>
          <div className="ml-auto text-gradient text-xs font-bold">
            {balance ? (
              <span>{formatPrice(balance?.eth)}</span>
            ) : (
              <div className="h-3 w-8 bg-placeholder rounded animate-pulse" />
            )}
          </div>
        </div>

        <div className={cx('flex flex-row w-full items-center', `h-[${LINE_HEIGHT}px]`)}>
          <div className="h-full">
            <Image src={dollarIcon} width={LINE_HEIGHT} height={LINE_HEIGHT} alt="token icon" />
          </div>
          <div className="felx flex-col ml-2 text-sm">
            <div className="text-xs">StableCoins</div>
            <div className="text-2xs text-[#BDBDBD]">
              More on{' '}
              <Link href={`https://debank.com/profile/${address}`} target="_blank">
                <span className="text-2xs text-[#BDBDBD] underline">debank.com</span>
              </Link>
            </div>
          </div>
          <div className="ml-auto text-gradient">
            {balance ? (
              <div className="flex flex-col text-right">
                <div className="text-xs font-bold">{balance.stableCoinsFormatted}</div>
                <div className="text-2xs text-gradient opacity-50">
                  {`${
                    balance.stableCoinsETHEquivalent.lt(ethers.utils.parseEther('0.0001'))
                      ? ''
                      : '≈ '
                  }
                  ${formatPrice(balance.stableCoinsETHEquivalent)}`}
                </div>
              </div>
            ) : (
              <div className="h-3 w-8 bg-placeholder rounded animate-pulse" />
            )}
          </div>
        </div>
      </div>
    </BaseCard>
  )
}

export default memo(TokenHoldingCard)
