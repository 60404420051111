import { Link } from '@nextui-org/react'
import { ethers } from 'ethers'
import Image from 'next/image'
import { FC, memo } from 'react'
import ReceiveIcon from '~/public/receive.png'
import SendIcon from '~/public/send.png'
import { getItemOpenSeaUrl } from '~/utils'
import dayjs from '~/utils/day'
import { AssociatedTxn } from '~/types'
import { ENS_CONTRACT } from '~/utils/consts'

type Props = AssociatedTxn

const TransiactionItem: FC<Props> = props => {
  const { type, value, token, from, to, timestamp } = props

  return (
    <div className="flex min:h-8 sm:min:h-9 w-full items-center">
      <div className="w-6 h-6 sm:w-9 sm:h-9 flex-shrink-0">
        <Image
          src={type === 'send' ? SendIcon : ReceiveIcon}
          width={36}
          height={36}
          alt="txn type"
        />
      </div>
      <div className="flex flex-col text-[#333333] ml-1.5 sm:ml-2.5">
        <span className="text-inter-xs sm:text-inter-sm">
          {type === 'send' ? (
            <>
              Sent <Token value={value} token={token} /> to {to}
            </>
          ) : (
            <>
              Received <Token value={value} token={token} /> from {from}
            </>
          )}
        </span>
        <span className="text-inter-xs opacity-30 mt-[3px]">{dayjs(timestamp).fromNow()}</span>
      </div>
    </div>
  )
}

interface TokenProps {
  value?: number
  token?: AssociatedTxn['token']
}
const Token = memo<TokenProps>(props => {
  const { value, token } = props
  if (token) {
    return (
      <Link href={getItemOpenSeaUrl(token.contract, token.tokenId)} target="_blank">
        <span className="text-[#1D9BF0]">
          {token.collection}{' '}
          {token.contract.toLowerCase() === ENS_CONTRACT ? token.tokenName : `#${token.tokenId}`}
        </span>
      </Link>
    )
  }
  return (
    <span>
      {value}
      {ethers.constants.EtherSymbol}
    </span>
  )
})
Token.displayName = 'Token'

export default memo(TransiactionItem)
