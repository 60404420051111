import { listTransactions } from '~/api/etherscan'
import { countBy, identity, range } from 'lodash-es'
import dayjs from '~/utils/day'

function addWithBound(limit: number) {
  return function (current: number, delta: number) {
    delta = delta % limit
    let newVal = current + delta
    if (newVal < 0) {
      return limit + newVal
    }
    return newVal % limit
  }
}
const addHour = addWithBound(24)

export async function fetchTxDistribution(address: string) {
  const txs = await listTransactions(address, 'txlist')
  let hourDisMap = countBy(
    txs.map((tx) => {
      return dayjs.unix(parseInt(tx.timeStamp)).hour()
    }),
    identity,
  )

  const hourDis = range(0, 24).map((i) => [i, hourDisMap[i.toString()] || 0])
  console.log('active hour distribution', JSON.stringify(hourDis))

  // find center by 5 hours stretch
  let max = 0,
    center = 0
  for (let i = 0; i < hourDis.length; i++) {
    const current =
      hourDis[addHour(i, -2)][1] +
      hourDis[addHour(i, -1)][1] +
      hourDis[i][1] +
      hourDis[addHour(i, 1)][1] +
      hourDis[addHour(i, 2)][1]
    if (current > max) {
      max = current
      center = i
    }
  }
  if (max === 0) {
    return
  }
  let bound = [center < 2 ? hourDis.length - 2 : center - 2, (center + 2) % hourDis.length]
  const average = max / 5
  // shrink: exclude 0 value from edge
  while (!hourDisMap[bound[0].toString()]) {
    bound[0] = addHour(bound[0], 1)
  }

  while (!hourDisMap[bound[bound.length - 1].toString()]) {
    bound[1] = addHour(bound[1], -1)
  }
  // expand: include value >= 70% of average value in the 5 hours from edge
  let leftBoundCnt1 = hourDisMap[addHour(bound[0], -1).toString()]
  let leftBoundCnt2 = hourDisMap[addHour(bound[0], -2).toString()]

  while (
    (leftBoundCnt1 + leftBoundCnt2 > 1.2 * average || leftBoundCnt1 > 0.6 * average) &&
    leftBoundCnt1 > 8 &&
    bound[0] != bound[1]
  ) {
    bound[0] = addHour(bound[0], -1)
    leftBoundCnt1 = hourDisMap[addHour(bound[0], -1).toString()]
    leftBoundCnt2 = hourDisMap[addHour(bound[0], -2).toString()]
  }

  let rightBoundCnt1 = hourDisMap[addHour(bound[1], 1).toString()]
  let rightBoundCnt2 = hourDisMap[addHour(bound[1], 2).toString()]
  while (
    (rightBoundCnt1 + rightBoundCnt2 > 1.2 * average || rightBoundCnt1 > 0.6 * average) &&
    rightBoundCnt1 > 8 &&
    bound[0] != bound[1]
  ) {
    bound[1] = addHour(bound[1], 1)
    rightBoundCnt1 = hourDisMap[addHour(bound[1], 1).toString()]
    rightBoundCnt2 = hourDisMap[addHour(bound[1], 2).toString()]
  }
  console.log('major active time', bound)
  return bound as [number, number]
}
