import { CSSProperties, FC, memo, useCallback, useMemo } from 'react'
import { scaleLinear } from '@visx/scale'
import Image from 'next/image'
import classNames from 'classnames'
import { formatAddress } from '~/utils/formatter'

interface Props {
  data: { label: string; value: number; displayLabel?: string }[]
  selectedLabel?: string
  onBarClick?: (label: string) => void
}

const RGB_PALETTE = [
  '128, 49, 255',
  '111, 65, 255',
  '97, 77, 255',
  '83, 90, 255',
  '70, 101, 255',
  '45, 124, 255',
  '43, 126, 255',
  '33, 134, 255',
  '20, 146, 255',
  '8, 157, 255',
]

const HorizontalBarChart: FC<Props> = (props) => {
  const { data, selectedLabel, onBarClick } = props

  const maxValue = useMemo(() => {
    let max = data[0].value
    data.forEach((item) => {
      if (item.value > max) {
        max = item.value
      }
    })
    return max
  }, [data])

  const scale = useMemo(
    () =>
      scaleLinear({
        domain: [1, maxValue],
        range: [30, 100],
      }),
    [maxValue],
  )

  const scaledWidth = useMemo(() => {
    const res = data.map((item) => scale(item.value))
    return res
  }, [data, scale])

  return (
    <div className="flex flex-col w-full space-y-[5px]">
      {data.map((item, index) => (
        <Bar
          {...item}
          index={index}
          key={item.label}
          width={`${scaledWidth[index]}%`}
          selected={item.label === selectedLabel}
          onBarClick={onBarClick}
        />
      ))}
    </div>
  )
}

const Bar = memo<{
  label: string
  displayLabel?: string
  selected: boolean
  index: number
  width: number | string
  onBarClick?: (label: string) => void
}>((props) => {
  const { label, displayLabel, selected, index, width, onBarClick } = props

  const onClick = useCallback(() => {
    onBarClick?.(label)
  }, [label, onBarClick])

  const style: CSSProperties = useMemo(
    () => ({
      backgroundColor: `rgb(${RGB_PALETTE[index]})`,
      boxShadow: selected ? `0px 0px 10px rgba(${RGB_PALETTE[index]}, 0.5)` : undefined,
      width,
    }),
    [index, selected, width],
  )
  return (
    <div
      key={label}
      style={style}
      onClick={onClick}
      className={classNames(
        'h-[30px] rounded-[30px] pl-1 pr-4 flex items-center cursor-pointer transition-opacity duration-300 opacity-50 hover:opacity-75',
        selected && '!opacity-100',
      )}
    >
      <span
        style={{ color: `rgb(${RGB_PALETTE[index]})` }}
        className="font-bold text-inter-xs text-white bg-white rounded-full p-1 w-[23px] text-center flex items-center justify-center scale-[.8]"
      >
        #{index + 1}
      </span>
      <span className="font-bold text-inter-xs text-white mr-auto ml-2 truncate">
        {formatAddress(displayLabel ?? label)}
      </span>
      {selected && (
        <div className={classNames('w-4 h-4 flex')}>
          <Image src="/right-arrow.svg" width={16} height={16} alt="selected" />
        </div>
      )}
    </div>
  )
})
Bar.displayName = 'Bar'

export default memo(HorizontalBarChart)
