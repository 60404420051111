import { AxiosResponse } from 'axios'
import { chunk, flatten } from 'lodash-es'
import pMemoize from 'p-memoize'
import { buildSearchParams } from '~/utils'
import logger from '~/utils/logger'
import { client, proxyClient } from './client'

interface Asset {
  id: number
  token_id: number
  name: string
  description: string
  image_url: string
  permalink: string
  collection: {
    name: string
    slug: string
    image_url: string
  }
  asset_contract: {
    address: string
  }
}

interface HoldingCollection {
  primary_asset_contracts: {
    address: string //'0xfd43d1da000558473822302e1d44d81da2e4cc0d',
    asset_contract_type: string //'semi-fungible'
    created_date: string //'2022-05-19T23:33:33.411643'
    name: string //'Love, Death + Robots'
    schema_name: string //'ERC1155'
    symbol: string //'LDR'
    description: string //'Nine Love, Death + Robots QR-Coded Artworks have been strewn across the digital and physical world. Each piece of special, limited edition imagery reflects Love, Death + Robots’ unique collective of visual perspectives and creative storytelling from Volume 3. To collect them all, you’ll have to be vigilant.'
    external_link: string //'https://lovedeathandart.com'
    image_url: string //'https://lh3.googleusercontent.com/RlK90xsdiBPiOq96fNycbKJD6hkfyWAOpgMuW15TQmGHBQIapx4oa3gfF8f6T66UUgQybHBgJJIl0ZJUlfl3x2vjYsnxzTmh88nbJLU=s120'
  }[]
  stats: {
    one_day_volume: number //0.0677
    one_day_change: number //-0.1769097032254928
    one_day_sales: number //20.0
    one_day_average_price: number //0.003385
    seven_day_volume: number //0.8807030000000006
    seven_day_change: number //-0.5135937960230335
    seven_day_sales: number //245.0
    seven_day_average_price: number //0.003594706122448982
    thirty_day_volume: number //9.832193096023953
    thirty_day_change: number //-0.5446826050934466
    thirty_day_sales: number //2156.0
    thirty_day_average_price: number //0.004560386408174375
    total_volume: number //31.42634523256126
    total_sales: number //7550.0
    total_supply: number //9.0
    count: number //9.0
    num_owners: number //31162
    average_price: number //0.004162429832127319
    num_reports: number //1
    market_cap: number //0.03235235510204084
    floor_price: number //0
  }
  banner_image_url: number //'https://lh3.googleusercontent.com/9ePoUZNQhu3xXRFg_NvwQidD2-lBSmM8c9IwWt67Ki6UjXzEID2nn94PLLjp9lHHweCXrJte4MWm5fk-c36H90oQU5XNRdG_4l073ts=s2500'
  created_date: string //'2022-05-20T06:17:01.172642'
  description: string //'Nine Love, Death + Robots QR-Coded Artworks have been strewn across the digital and physical world. Each piece of special, limited edition imagery reflects Love, Death + Robots’ unique collective of visual perspectives and creative storytelling from Volume 3. To collect them all, you’ll have to be vigilant.'
  external_url: string //'https://lovedeathandart.com'
  hidden: false
  image_url: string //'https://lh3.googleusercontent.com/RlK90xsdiBPiOq96fNycbKJD6hkfyWAOpgMuW15TQmGHBQIapx4oa3gfF8f6T66UUgQybHBgJJIl0ZJUlfl3x2vjYsnxzTmh88nbJLU=s120'
  is_subject_to_whitelist: boolean //false
  large_image_url: string //'https://lh3.googleusercontent.com/S1AceNBOr1LJ99Uj-yn4aLTbAXPyEFFgI7ymKb4CtnWmF3GSI0ze1JTqe4tlkSW41K3y_VZso5IdNx74RESEvpU3hIrC7IfbPc4waRs=s300'
  medium_username?: string //null
  name: string //'Love, Death + Robots Official'
  slug: string //'love-death-robots-official'
  telegram_url?: string
  twitter_username?: string
  instagram_username?: string
  wiki_url?: string
  is_nsfw: boolean
  owned_asset_count: number //1
}

interface ContractData {
  collection: {
    name: string
    slug: string
    image_url: string
  }
  address: string
}

async function _batchGetAssets(
  assets: { contractAddress: string; tokenId: string }[],
): Promise<Asset[]> {
  let cursor: string | null = null
  let results: Asset[] = []
  let resp: AxiosResponse<{ assets: Asset[]; next: string | null }>
  do {
    logger.debug('fetching assets from opensea %d', assets.length)
    const params = buildSearchParams({
      limit: 50,
      ...(cursor ? { cursor } : {}),
      token_ids: assets.map((a) => a.tokenId),
      asset_contract_addresses: assets.map((a) => a.contractAddress),
    })
    resp = await client.get('/api/v1/assets', { params, timeout: 10 * 1000 }).catch((err) => {
      console.error('opensea error', err)
      return proxyClient.get('/api/v1/assets', { params }) // retry with proxy
    })
    results.push(...resp.data.assets)
    cursor = resp.data.next
  } while (cursor)
  return results
}

export async function batchGetAssets(
  assets: { contractAddress: string; tokenId: string }[],
): Promise<Asset[]> {
  if (!assets.length) {
    return []
  }
  const results = await Promise.all(chunk(assets, 30).map((as) => _batchGetAssets(as)))
  return flatten(results)
}

interface Account {
  name: string
  profile_img_url: string
}

export const getAccount = pMemoize(async function (address: string): Promise<Account> {
  const { data: resp } = await client.get<{
    data: {
      user: null | { username: string }
      profile_img_url: string
    }
  }>(`/api/v1/account/${address}`)
  return {
    name: resp.data.user?.username || 'Unknown',
    profile_img_url: resp.data.profile_img_url,
  }
})

export async function getHoldingCollections(address: string) {
  let offset = 0
  let result = [] as HoldingCollection[]
  let { data: resp } = await client.get<HoldingCollection[]>('/api/v1/collections', {
    params: {
      asset_owner: address,
      limit: 300,
      offset,
    },
  })
  result = [...resp]
  while (resp.length >= 300) {
    offset += 300
    resp = (
      await client.get<HoldingCollection[]>('/api/v1/collections', {
        params: {
          asset_owner: address,
          limit: 300,
          offset,
        },
      })
    ).data
    result.push(...resp)
  }
  return result.filter((it) => it.primary_asset_contracts.length > 0)
}

export async function getOwnedAssets(address: string, contracts: string[]) {
  const assets: Asset[] = []
  let cursor: string | null = null
  do {
    const { data: resp } = await client.get<{ assets: Asset[]; next?: string }>('api/v1/assets', {
      params: buildSearchParams({
        owner: address,
        asset_contract_addresses: contracts,
        cursor: cursor ?? '',
        limit: 50,
      }),
    })
    assets.push(...resp.assets)
    cursor = resp.next as string
  } while (assets.length < 300 && cursor)
  return assets
}

export const getContractCollection = pMemoize(async function (contract: string) {
  const { data: resp } = await client.get<ContractData>(`api/v1/asset_contract/${contract}`)
  return resp
})

export type { Asset, HoldingCollection }
