import { FC, memo, useMemo } from 'react'
import useENS from '~/hooks/useENS'
import BaseCard from '../BaseCard'
import Attributes from './Attributes'
import BaseInfo from './BaseInfo'
import BlurBackground from './BlurBackground'
import Footer from './Footer'
import Labels from './Labels'
import { useRouter } from 'next/router'
import useAccountInfo from '~/hooks/useAccountInfo'

interface Props {}

const BaseInfoCard: FC<Props> = () => {
  const router = useRouter()
  const addressOrEns = (router.query.address as string)?.toLowerCase()
  const profile = useAccountInfo()
  const { data: ENS, isFetching: isLoadingENS } = useENS(profile.address)

  const screenName = useMemo(() => {
    if (ENS) {
      return ENS
    }
    if (!isLoadingENS && profile?.screenName) {
      return profile.screenName
    }
    if (addressOrEns && addressOrEns.endsWith('.eth')) {
      return addressOrEns
    }
    return ''
  }, [ENS, addressOrEns, profile, isLoadingENS])

  return (
    <BaseCard className="w-full h-full !p-0 !pb-0" style={{ gridArea: 'base-info' }}>
      <div className="flex flex-col w-full pb-card sm:h-[280px] bg-[#303438] rounded-card p-xs-card sm:p-card  overflow-hidden relative">
        <BlurBackground />
        <BaseInfo imgUrl={profile.imgUrl} screenName={screenName} address={profile.address} />
        <Attributes attributes={profile.attributes} />
        <Labels labels={profile.labels} />
      </div>
      <Footer twitter={profile.twitter} opensea={profile.opensea} />
    </BaseCard>
  )
}

export default memo(BaseInfoCard)
