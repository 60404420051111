import { useCallback } from 'react'
import useAddressParams from './useAddressParams'
import { ethers } from 'ethers'
import { getERC20Balance, getEtherBalance } from '~/api/etherscan'
import { useQuery } from 'react-query'
import { getETHPrice } from '~/api/coingecko'
import {
  CONTRACT_BUSD,
  CONTRACT_DAI,
  CONTRACT_FRAX,
  CONTRACT_USDC,
  CONTRACT_USDT,
  CONTRACT_WETH,
} from '~/utils/consts'

export default function useTokenHolding() {
  const address = useAddressParams()

  const fetcher = useCallback(async () => {
    if (!address) return
    const [
      etherBalance,
      wethBalance,
      usdtBalance,
      usdcBalance,
      busdBalance,
      daiBalance,
      fraxBalance,
      ethPrice,
    ] = await Promise.all([
      getEtherBalance(address),
      getERC20Balance(address, CONTRACT_WETH),
      getERC20Balance(address, CONTRACT_USDT),
      getERC20Balance(address, CONTRACT_USDC),
      getERC20Balance(address, CONTRACT_BUSD),
      getERC20Balance(address, CONTRACT_DAI),
      getERC20Balance(address, CONTRACT_FRAX),
      getETHPrice(),
    ])
    const ETHBalance = ethers.utils
      .parseUnits(etherBalance!, 'wei')
      .add(ethers.utils.parseUnits(wethBalance!, 'wei'))

    console.log(
      'usdtBalance, usdcBalance, busdBalance, daiBalance, fraxBalance',
      usdtBalance,
      usdcBalance,
      busdBalance,
      daiBalance,
      fraxBalance,
    )

    const stableCoinBalance = [usdtBalance, usdcBalance, busdBalance, daiBalance, fraxBalance]
      .map((b, i) => {
        const balance = ethers.BigNumber.from(b || 0)
        if (i < 2) {
          // usdt and usdc decimal is 6
          return balance.mul(1e12)
        }
        return balance
      })
      .reduce((all, c) => all.add(c), ethers.BigNumber.from(0))
    const stableCoinsETHEquivalent =
      parseFloat(ethers.utils.formatUnits(stableCoinBalance, 18)) / ethPrice
    const totalETHEquivalent = ethers.utils.parseEther(
      (stableCoinsETHEquivalent + parseFloat(ethers.utils.formatEther(ETHBalance))).toFixed(18),
    )
    const stableCoinsFormatted = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'USD',
    }).format(parseFloat(ethers.utils.formatEther(stableCoinBalance)))
    return {
      totalETHEquivalent,
      stableCoins: stableCoinBalance,
      stableCoinsFormatted:
        stableCoinsFormatted == '$0.00' && stableCoinBalance.gt(0)
          ? '< $0.01'
          : stableCoinsFormatted,
      stableCoinsETHEquivalent: ethers.utils.parseEther(stableCoinsETHEquivalent.toFixed(18)),
      eth: ETHBalance,
    }
  }, [address])

  return useQuery(['token-holding', address], fetcher, { enabled: !!address, staleTime: Infinity })
}
