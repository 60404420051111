import axios, { AxiosError } from 'axios'
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry'
import { sample } from 'lodash-es'

const ETHERSCAN_API_KEYS = [
  'Y56BF6PXMUZPDGC82YX2SQK5HV7EMBG1B8',
  '1764TRITEGDU1FQZG16B641C9CMBSNJB1Q',
  'RTIPWA3QRYYUEVGA9VN57V6UUG571RV3TB',
  '8DRIPRJBDSQQ3HVS5F3GUU7YFVCMC3R68M',
  'VGW1CAIWZKTCZAFV2CI94E7S4TDYYA1IGF',
  'MFV43SYYUM54X26F1DMZIHA3XJXJVPJ9WJ',
  'VR9IVVURYC4N5SW2ZWQ6VQWHT7FPAVVYEP',
  '3D9VGNBFTH1KYGY2YIE4YE3EVKGRHGJ4ZT',
]

type TxType = 'txlist' | 'txlistinternal' | 'tokentx' | 'tokennfttx'

interface Response<R> {
  status: string
  message: string
  result: R
}

interface BaseTransaction {
  type: TxType
  blockNumber: string
  timeStamp: string
  hash: string
  blockHash: string
  transactionIndex: string
  from: string
  to: string
  value: string
  gas: string
  gasPrice: string
  gasUsed: string
}

export interface NormalTransaction extends BaseTransaction {
  type: 'txlist'
}

export interface InternalTransaction extends BaseTransaction {
  type: 'txlistinternal'
  contractAddress: string
}

export interface TokenTransaction extends BaseTransaction {
  contractAddress: string
  tokenName: string
  tokenSymbol: string
  tokenDecimal: string
}

export interface ERC20Transaction extends TokenTransaction {
  type: 'tokentx'
}

export interface NFTTransaction {
  type: 'tokennfttx'
  hash: string
  contractAddress: string
  tokenID: string
  from: string
  to: string
  timeStamp: string
}

export type Transaction =
  | NormalTransaction
  | InternalTransaction
  | ERC20Transaction
  | NFTTransaction

const client = axios.create({
  baseURL: 'https://api.etherscan.io/api',
})

client.interceptors.response.use((resp) => {
  if (
    resp.status === 200 &&
    resp.data.status === '0' &&
    resp.data.result === 'Max rate limit reached'
  ) {
    resp.status = 429
    throw new AxiosError(
      resp.data.result,
      AxiosError.ERR_BAD_RESPONSE,
      resp.config,
      resp.request,
      resp,
    )
  }
  return resp
})

axiosRetry(client, {
  retries: 3,
  retryDelay(retryCount: number) {
    console.log('retry', retryCount)
    return axiosRetry.exponentialDelay(retryCount)
  },
  retryCondition(err: AxiosError) {
    console.error('etherscan error', err)
    return (
      isNetworkOrIdempotentRequestError(err) ||
      err.code === AxiosError.ERR_NETWORK ||
      err.response?.status === 429
    )
  },
})

async function listTransactions<T extends Transaction>(
  address: string,
  txType: TxType,
  offset = 1000,
): Promise<T[]> {
  const resp = await client.get<Response<T[]>>('/', {
    params: {
      module: 'account',
      action: txType,
      address,
      page: 1,
      offset,
      sort: 'desc',
      apikey: sample(ETHERSCAN_API_KEYS),
    },
  })
  
  if (resp.data.status !== '1') {
    if (resp.data.message === 'No transactions found') {
      return []
    }
    throw new Error(resp.data.message)
  }
  return resp.data.result.map((r) => ({ ...r, type: txType }))
}

async function getFirstTransaction(address: string, sort: 'asc'|'desc' = 'asc') {
  const resp = await client.get<Response<NormalTransaction[]>>('/', {
    params: {
      module: 'account',
      action: 'txlist',
      address,
      page: 1,
      offset: 10,
      sort,
      apikey: sample(ETHERSCAN_API_KEYS),
    },
  })
  if (resp.data.status !== '1') {
    if (resp.data.message === 'No transactions found') {
      return undefined
    }
    throw new Error(resp.data.message)
  }
  return resp.data.result[0]
}

async function getEtherBalance(address: string) {
  const resp = await client.get<Response<string>>('/', {
    params: {
      module: 'account',
      action: 'balance',
      address,
      tag: 'latest',
      apikey: sample(ETHERSCAN_API_KEYS),
    },
  })
  if (resp.data.status !== '1') {
    if (resp.data.message === 'No transactions found') {
      return undefined
    }
    throw new Error(resp.data.message)
  }
  return resp.data.result
}

async function getERC20Balance(address: string, contractaddress: string) {
  const resp = await client.get<Response<string>>('/', {
    params: {
      module: 'account',
      action: 'tokenBalance',
      address,
      contractaddress,
      tag: 'latest',
      apikey: sample(ETHERSCAN_API_KEYS),
    },
  })
  if (resp.data.status !== '1') {
    if (resp.data.message === 'No transactions found') {
      return undefined
    }
    throw new Error(resp.data.message)
  }
  return resp.data.result
}

export { getEtherBalance, getERC20Balance, getFirstTransaction, listTransactions }
