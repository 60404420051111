import { FC, memo, useMemo } from 'react'
import HorizontalBarChart from '~/components/Charts/HorizontalBarChart'
import { AssociatedWallet } from '~/hooks/useAssociatedWallets'

interface Props {
  data: AssociatedWallet[]
  selectedAddress?: string
  setSelectedAddress: (address: string) => void
}

const AssociatedWalletsList: FC<Props> = (props) => {
  const { data, selectedAddress, setSelectedAddress } = props
  const chartData = useMemo(
    () =>
      data.map((item) => ({
        label: item.address,
        value: +item.count,
        displayLabel: item.ens ?? item.address,
      })),
    [data],
  )

  return (
    <HorizontalBarChart
      data={chartData}
      selectedLabel={selectedAddress}
      onBarClick={setSelectedAddress}
    />
  )
}

export default memo(AssociatedWalletsList)
