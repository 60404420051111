/* eslint-disable @next/next/no-img-element */
import { FC, memo, useCallback } from 'react'
import { formatAddress } from '~/utils/formatter'
import CopyImage from '~/assets/copy.svg'
import CopySuccess from '~/assets/copy-success.svg'
import Image from 'next/image'
import { useClipboard } from 'use-clipboard-copy'

interface BaseInfoProps {
  imgUrl?: string
  screenName?: string
  address?: string
  loading?: boolean
}
const BaseInfo: FC<BaseInfoProps> = props => {
  const { imgUrl, screenName, address } = props
  const displayAddress = screenName ? formatAddress(screenName) : undefined
  const clipboard = useClipboard({ copiedTimeout: 1000 })

  const onClickCopyButton = useCallback(() => {
    if (!address) return
    clipboard.copy(address)
  }, [address, clipboard])

  return (
    <div className="flex items-center z-10 h-20">
      {imgUrl ? (
        <img
          src={imgUrl}
          className="w-16 h-16 sm:w-20 sm:h-20 rounded-full bg-placeholder flex-shrink-0 object-cover"
          alt="avatar"
        />
      ) : (
        <div className="w-20 h-20 rounded-full bg-placeholder flex-shrink-0 animate-pulse"></div>
      )}
      <div className="flex flex-col ml-2.5 space-y-[5px] overflow-hidden">
        {displayAddress ? (
          <span className="text-inter-xl sm:text-inter-2xl text-white font-bold truncate">
            {displayAddress}
          </span>
        ) : (
          <span className="h-7 bg-placeholder w-40 animate-pulse rounded" />
        )}
        {address ? (
          <div className="flex items-center overflow-hidden">
            <span className="text-inter-xs text-white mr-[5px] truncate">
              {formatAddress(address)}
            </span>
            <button
              role="button"
              className="flex flex-shrink-0 w-4 h-4"
              onClick={onClickCopyButton}
            >
              {clipboard.copied ? (
                <Image src={CopySuccess} width={16} height={16} alt="copy success icon" />
              ) : (
                <Image src={CopyImage} width={16} height={16} alt="copy icon" />
              )}
            </button>
          </div>
        ) : (
          <span className="h-4 bg-placeholder w-60 animate-pulse rounded" />
        )}
      </div>
    </div>
  )
}

export default memo(BaseInfo)
