import { Link } from '@nextui-org/react'
import Image from 'next/image'
import { FC, useEffect, useState } from 'react'
import { isMobile, isChrome } from 'react-device-detect'
import downloadPic from '~/assets/ext-download.png'

const ExtDownload: FC<{}> = () => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    if (isMobile || !isChrome) {
      return
    }
    setTimeout(() => {
      if (!document.getElementById('__trivia-labs__')) {
        setShow(true)
      }
    }, 1000)
  }, [setShow])
  return show ? (
    <div className="fixed right-32 bottom-16 z-50">
      <Link
        href="https://chrome.google.com/webstore/detail/0x-watch/efgnonjdjdpncdceljgjbopegddjojhn"
        target="_blank"
      >
        <Image src={downloadPic} width={280} height={110} />
      </Link>
    </div>
  ) : null
}
export default ExtDownload
