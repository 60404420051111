import { FC, memo, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import useAssociatedWallets from '~/hooks/useAssociatedWallets'
import BaseCard from '../BaseCard'
import AssociatedDetail from './AssociatedDetail'
import AssociatedWalletsList from './AssociatedWalletsList'
interface Props {}

const AssociatedWalletsCard: FC<Props> = () => {
  const { data, isFetching } = useAssociatedWallets()
  const [selectedAddress, setSelectedAddress] = useState<string | undefined>()

  const currentIndex = useMemo(
    () => data?.transfers?.findIndex((item) => item.address === selectedAddress),
    [data, selectedAddress],
  )

  const selectedAddressENS = useMemo(() => {
    if (!data?.transfers || currentIndex === undefined) return
    return data.transfers[currentIndex]?.ens
  }, [data, currentIndex])

  useEffect(() => {
    if (!data?.transfers || data.transfers?.length === 0) return
    setSelectedAddress(data.transfers[0].address)
  }, [data])

  return (
    <BaseCard
      className={cx(
        'w-full lg:min-h-[240px] lg:max-h-[450px] !p-0 !pb-0 transition-all',
        isFetching ? 'lg:max-h-[240px]' : 'lg-max-h-[450px]',
      )}
      style={{ gridArea: 'associated-wallets' }}
    >
      <div className="flex flex-col lg:flex-row h-full relative">
        <div className="flex flex-col lg:w-1/2 p-xs-card sm:p-card pb-[15px]">
          <h3 className="text-[#333333] text-card-xs-title sm:text-card-title font-bold mb-5">
            Associated Wallets
          </h3>
          {isFetching ? (
            <div className="flex flex-col w-full space-y-[5px]">
              <div className={'h-[30px] rounded-[30px] bg-placeholder animate-pulse'} />
              <div className={'h-[30px] rounded-[30px] bg-placeholder animate-pulse'} />
              <div className={'h-[30px] rounded-[30px] bg-placeholder animate-pulse'} />
            </div>
          ) : data?.transfers && data.transfers.length > 0 ? (
            <div className={cx(!data.isDone && 'animate-pulse')}>
              <AssociatedWalletsList
                data={data.transfers || []}
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
              />
            </div>
          ) : (
            <span className="m-auto text-inter-xs text-[#707070]/50 select-none">
              No associated wallets
            </span>
          )}
        </div>
        <div className="flex flex-col lg:w-1/2 p-xs-card sm:p-card pb-[15px] bg-[#FBFBFB]">
          {selectedAddress && currentIndex !== undefined ? (
            <AssociatedDetail
              selectedAddressENS={selectedAddressENS}
              selectedAddress={selectedAddress}
              currentIndex={currentIndex}
            />
          ) : (
            <span className="m-auto text-inter-xs text-[#707070]/50 select-none">
              Select one wallet first
            </span>
          )}
        </div>
      </div>
    </BaseCard>
  )
}

export default memo(AssociatedWalletsCard)
