import { Link } from '@nextui-org/react'
import { ethers } from 'ethers'
import { FC, memo, useMemo } from 'react'
import { HoldingCollection } from '~/api/opensea'
import useAddressParams from '~/hooks/useAddressParams'

interface Props {
  holdingList?: HoldingCollection[]
  loading?: boolean
}
const PLACEHOLDER_COUNT = 5
const PLACEHOLDER_ARRAY = Array(PLACEHOLDER_COUNT).fill(0)

const HoldingList: FC<Props> = (props) => {
  const { holdingList, loading = false } = props
  return (
    <div className="flex flex-col h-[273px]">
      <div className="flex items-center py-2 px-[15px] rounded-t-[20px] bg-[#F7F7F7] border border-[#E5E5E5] font-medium text-inter-xs text-[#aaaaaa]">
        <span className="w-[160px]">Collection</span>
        <span className="mx-5 w-[35px]">Count</span>
        <span>EST.Value</span>
      </div>
      {loading || !holdingList
        ? PLACEHOLDER_ARRAY.map((_, index) => (
            <HoldingListItemPlaceholder key={`placeholder-${index}`} />
          ))
        : holdingList.map((holding) => <HoldingListItem key={holding.name} {...holding} />)}
    </div>
  )
}

const HoldingListItem = memo<HoldingCollection>((props) => {
  // const { ownership, collection } = props
  const address = useAddressParams()

  return (
    <div className="flex items-center border border-[#E5E5E5] border-t-0 last-of-type:rounded-b-[20px] p-[15px]">
      <Link
        href={`https://opensea.io/${address}/${props.slug}?tab=collected&search[sortBy]=LISTING_DATE`}
        target="_blank"
      >
        <span className="text-inter-sm font-bold text-[#303030] truncate w-[160px]">
          {props.name}
        </span>
      </Link>
      <span className="text-inter-xs font-medium text-[#AAAAAA] mx-5 w-[35px]">
        {props.owned_asset_count}
      </span>
      <span className="text-inter-xs font-medium text-[#AAAAAA]">
        {(props.owned_asset_count * props.stats.seven_day_average_price).toFixed(2)}
        {ethers.constants.EtherSymbol}
      </span>
    </div>
  )
})

HoldingListItem.displayName = 'HoldingListItem'

const HoldingListItemPlaceholder = memo(() => {
  return (
    <div className="flex items-center border border-[#E5E5E5] border-t-0 last-of-type:rounded-b-[20px] p-[15px]">
      <div className="animate-pulse w-full h-[17px] bg-placeholder rounded" />
    </div>
  )
})

HoldingListItemPlaceholder.displayName = 'HoldingListItemPlaceholder'

export default memo(HoldingList)
