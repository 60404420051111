import { useQuery } from 'react-query'
import { getTwitter } from '~/api/cyberconnect'
import { getSocialLinks } from '~/api/trade'
import useAddressParams from './useAddressParams'
import useOpenseaAccount from './useOpenSeaAccount'

export default function useSocialLinks() {
  const { data: account } = useOpenseaAccount()
  const address = useAddressParams()
  return useQuery(
    ['social-links', address, account?.name],
    () => {
      if (!address || !account) {
        return
      }
      if (account?.name != 'Unknown') {
        return Promise.all([getSocialLinks(address, account.name), getTwitter(address)]).then(
          ([osTwitter, cyberconnectTwitter]) => {
            if (osTwitter?.twitter) {
              return osTwitter
            }
            return {
              twitter: cyberconnectTwitter ? `https://twitter.com/${cyberconnectTwitter}` : '',
            }
          },
        )
      } else {
        return getTwitter(address).then((cyberconnectTwitter) => {
          return {
            twitter: cyberconnectTwitter ? `https://twitter.com/${cyberconnectTwitter}` : '',
          }
        })
      }
    },
    {
      staleTime: Infinity,
      enabled: !!account && !!address,
    },
  )
}
