import { QueryFunction, useQuery } from 'react-query'
import { useCallback } from 'react'
import { getFirstTransaction } from '~/api/etherscan'
import { fetchTxDistribution } from '~/api/fetch-tx-distribution'

function normalizeActiveTime(bound: [number, number]): string {
  let leftBound: string
  let rightBound: string

  if (bound[0] == 12) {
    leftBound = '12pm'
  } else if (bound[0] == 0) {
    leftBound = '12am'
  } else if (bound[0] > 12) {
    leftBound = bound[0] - 12 + 'pm'
  } else {
    leftBound = bound[0] + 'am'
  }

  if (bound[1] + 1 == 12) {
    rightBound = '12pm'
  } else if (bound[1] + 1 == 24) {
    rightBound = '12am'
  } else if (bound[1] + 1 > 12) {
    rightBound = bound[1] + 1 - 12 + 'pm'
  } else {
    rightBound = bound[1] + 1 + 'am'
  }
  return `${leftBound}~${rightBound}`
}

export default function useAccountAttributes(address?: string) {
  const fetcher = useCallback<QueryFunction<any, [string, string]>>(async ({ queryKey }) => {
    const address = queryKey[1]
    if (!address) {
      return
    }
    const [firstTx, lastTx, txDistribution] = await Promise.all([
      getFirstTransaction(address),
      getFirstTransaction(address, 'desc'),
      fetchTxDistribution(address),
    ])

    if (!firstTx || !lastTx) {
      return
    }
    return {
      firstTxTime: firstTx.timeStamp,
      lastTxTime: lastTx.timeStamp,
      majorActiveTime: txDistribution ? normalizeActiveTime(txDistribution) : '',
    }
  }, [])

  return useQuery(['account-attributes', address || ''], fetcher, {
    enabled: !!address,
    staleTime: Infinity,
  })
}
